import React from "react";
import {
    Divider,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Tooltip,
    Typography,
} from "@mui/material";
import GridViewIcon from '@mui/icons-material/GridView';
import InventoryIcon from '@mui/icons-material/Inventory';
import DiamondIcon from '@mui/icons-material/Diamond';
import DraftsIcon from '@mui/icons-material/Drafts';
import BackupTableIcon from '@mui/icons-material/BackupTable';
import LoginIcon from '@mui/icons-material/Login';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import BadgeIcon from '@mui/icons-material/Badge';
import AssessmentIcon from '@mui/icons-material/Assessment';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import GrainIcon from '@mui/icons-material/Grain';
import WebAssetIcon from '@mui/icons-material/WebAsset';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import GroupsIcon from '@mui/icons-material/Groups';

interface SideBarProps {
    open: boolean;
}

const SidebarSection: React.FC<{ title: string, open: boolean }> = ({ title, open }) => (
    <Typography
        variant="subtitle2"
        sx={{
            px: 2,
            py: 1,
            fontSize: '12px',
            fontWeight: 200,
            textTransform: 'uppercase',
            color: 'text.secondary',
            letterSpacing: '0.05em',
            display: open ? 'block' : 'none', // Hide when `open` is false
        }}
    >
        {title}
    </Typography>
);

const getSideBarElement = (
    text: string,
    icon: React.ReactNode,
    open: boolean
) => (
    <ListItem disablePadding sx={{ display: 'block' }}>
        <Tooltip title={text} placement="right" arrow disableHoverListener={open}>
            <ListItemButton
                sx={{
                    minHeight: 48,
                    px: 2.5,
                    justifyContent: open ? 'initial' : 'center',
                    borderRadius: 1,
                    '&:hover': {
                        backgroundColor: 'rgba(0, 0, 0, 0.04)',
                    },
                }}
            >
                <ListItemIcon
                    title={text} // Tooltip text displayed on hover
                    sx={{
                        minWidth: 0,
                        justifyContent: 'center',
                        mr: open ? 3 : 'auto',
                        color: 'primary.main',
                    }}
                >
                    {icon}
                </ListItemIcon>
                <ListItemText
                    primary={text}
                    primaryTypographyProps={{
                        variant: 'body2',
                        color: 'text.primary',
                    }}
                    sx={{
                        opacity: open ? 1 : 0,
                        transition: 'opacity 0.3s ease',
                    }}
                />
            </ListItemButton>
        </Tooltip>
    </ListItem>
);

const Sidebar: React.FC<SideBarProps> = ({ open }) => (
    <List>

        {getSideBarElement('HaloBoard', <GridViewIcon />, open)}
        
        <Divider />
        <SidebarSection open={open} title="Assets" />
        {getSideBarElement('Inventory', <InventoryIcon />, open)}
        {getSideBarElement('Asset Valuation', <DiamondIcon />, open)}
        
        <Divider />
        <SidebarSection open={open} title="Documents" />
        {getSideBarElement('Policy Drafting', <DraftsIcon />, open)}
        {getSideBarElement('Repository', <BackupTableIcon />, open)}
        
        <Divider />
        <SidebarSection open={open} title="Access Control" />
        {getSideBarElement('Access Control Matrix', <LoginIcon />, open)}
        {getSideBarElement('Access Control Review', <RemoveRedEyeIcon />, open)}
        
        <Divider />
        <SidebarSection open={open} title="Risk Management" />
        {getSideBarElement('Risk Identification', <BadgeIcon />, open)}
        {getSideBarElement('Risk Assessment', <AssessmentIcon />, open)}
        {getSideBarElement('Risk Treatment', <AutoFixHighIcon />, open)}
        
        <Divider />
        <SidebarSection open={open} title="Classification" />
        {getSideBarElement('Data Classification', <GrainIcon />, open)}
        {getSideBarElement('Asset Classification', <WebAssetIcon />, open)}

        <Divider />
        <SidebarSection open={open} title="Settings"></SidebarSection>
        {getSideBarElement('Integrations', <IntegrationInstructionsIcon />, open)}
        {getSideBarElement('Teams', <GroupsIcon />, open)}
        
    </List>
);

export default Sidebar;
