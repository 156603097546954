import React from 'react';

import '../../styles/home.css';

function Home() {
	return (
		<div className="App">
			<header className="App-header">
				<p>
					home
				</p>
			</header>
		</div>
	);
}

export default Home;
