// src/utils/tokenUtils.tsx
export const setAuthToken = (token: string) => {
	// Store token in localStorage
	localStorage.setItem('authToken', token);
};

export const getAuthToken = (): string | null => {
	// Retrieve token from localStorage
	return localStorage.getItem('authToken');
};

export const removeAuthToken = () => {
	// Remove token from localStorage
	localStorage.removeItem('authToken');
};