import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Home from './admin/home';
import NotFound from './notfound';
import Header from '../components/common/header';
import Footer from '../components/common/footer';

// Define the type for the props, which includes the function
interface adminProps {
    toggleTheme: () => void; // The function type is (name: string) => void
}

const Pages: React.FC<adminProps> = ({ toggleTheme }) => {
    return (
        <>
            <Header toggleTheme={toggleTheme}></Header>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
            <Footer></Footer>
        </>
    );
};

export default Pages;
