import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const NotFound: React.FC = () => {
    const navigate = useNavigate();

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            height="100vh"
            textAlign="center"
        >
            <Typography variant="h1" color="error">
                404
            </Typography>
            <Typography variant="h5" color="textSecondary">
                Oops! The page you are looking for doesn't exist.
            </Typography>
            <Button
                variant="contained"
                color="primary"
                onClick={() => navigate('/admin')}
                sx={{ marginTop: 2 }}
            >
                Go Home
            </Button>
        </Box>
    );
};

export default NotFound;
