// src/api/api.ts
import axios from 'axios';
import { getAuthToken } from '../utils/tokenUtils';

const api = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}/api`,
    headers: {
        'Content-Type': 'application/json',
    },
});

// You can also add interceptors here to attach tokens, handle errors globally, etc.
api.interceptors.request.use((config) => {
    const token = getAuthToken() // Assuming token is stored in localStorage
    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
});

api.interceptors.response.use(
    (response) => response,
    (error) => {
        // Handle errors (e.g., token expiration, etc.)
        return Promise.reject(error);
    }
);

export default api;
