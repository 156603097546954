import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider, createTheme, CssBaseline } from '@mui/material';

import Login from './pages/login';
import Admin from './pages/admin';
import Employee from './pages/employee';
import NotFound from './pages/notfound';
import { getTheme, setTheme } from './utils/themeUtils';
import { getAuthToken } from './utils/tokenUtils';

const App: React.FC = () => {

    const themeMode = getTheme();
    // State for theme mode (light or dark)
    const [darkMode, setDarkMode] = useState<boolean>(themeMode && themeMode==='dark'? true: false);
    
    // Toggle theme mode
    const toggleTheme = () => {
        setTheme(darkMode ? 'dark' : 'light');
        setDarkMode((prevMode) => !prevMode);
    };

    // Create theme based on darkMode state
    const theme = createTheme({
        palette: {
            mode: darkMode ? 'dark' : 'light',
        },
    });

    // Optionally, persist theme mode in localStorage so it stays across sessions
    useEffect(() => {
        const savedTheme = localStorage.getItem('theme');
        if (savedTheme) {
            setDarkMode(savedTheme === 'dark');
        }
    }, []);

    useEffect(() => {
        localStorage.setItem('theme', darkMode ? 'dark' : 'light');
    }, [darkMode]);

    const ProtectedRoute = ({ children }: { children: any }) => {
        const token = getAuthToken();
        if (!token) {
            window.location.href = '/login';
            return ;
        }
        return children;
    };

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline /> {/* Applies the theme globally */}
            <Router>
                <div className="app">
                    <Routes>
                        <Route path="/login" element={<Login />} />
                        <Route path="/admin/*" element={<ProtectedRoute><Admin toggleTheme={toggleTheme} /></ProtectedRoute>} />
                        <Route path="/employee/*" element={<ProtectedRoute><Employee /></ProtectedRoute>} />
                        {/* Catch-all route for undefined paths */}
                        <Route path="*" element={<NotFound />} />
                    </Routes>
                </div>
            </Router>
        </ThemeProvider>
    );
};

export default App;