import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Button, TextField, Typography, Alert, Paper } from '@mui/material';

import '../styles/login.css';
import authService from '../services/auth.service';
import { isValidEmail, isValidPassword } from '../utils/validation';

// Styled Paper for the login container
const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(4),
    maxWidth: 400,
    margin: 'auto',
    marginTop: theme.spacing(10),
    boxShadow: theme.shadows[3],
    borderRadius: theme.spacing(2),
}));

const Login: React.FC = () => {

    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [error, setError] = useState<string>('');
    const [success, setSuccess] = useState<string>('');

    const handleLogin = async () => {
        setError('');
        setSuccess('');

        if (!isValidEmail(email)) {
            setError('Please enter a valid email address.');
            return;
        }
        if (!isValidPassword(password)) {
            setError('Password must be at least 6 characters long.');
            return;
        }

        try {
            const response = await authService.Login(email, password);
            setSuccess('Login successful!');
            if (response) {
                window.location.href = '/admin';
            }
        } catch (err: any) {
            setError(err.message);
        }
    };

    return (
        <StyledPaper>
            <Typography variant="h4" align="center" gutterBottom>
                Login
            </Typography>
            {error && <Alert severity="error">{error}</Alert>}
            {success && <Alert severity="success">{success}</Alert>}
            <Box component="form" sx={{ mt: 2 }}>
                <TextField
                    id="email"
                    label="Email Address"
                    type="email"
                    variant="outlined"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    fullWidth
                    margin="normal"
                    error={!!error && !isValidEmail(email)} // Highlight field if invalid
                    helperText={!isValidEmail(email) && email ? "Invalid email format" : ""}
                />
                <TextField
                    id="password"
                    label="Password"
                    type="password"
                    variant="outlined"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    fullWidth
                    margin="normal"
                    error={!!error && password.length < 6 && password.length > 0} // Highlight field if invalid
                    helperText={password.length > 0 && password.length < 6 ? "Password is too short" : ""}
                />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleLogin}
                    fullWidth
                    sx={{ mt: 2, py: 1.5 }}
                >
                    Login
                </Button>
            </Box>
        </StyledPaper>
    );
};

export default Login;
