// src/services/auth.service.ts
import api from './api';
import { setAuthToken } from '../utils/tokenUtils';

interface AuthResponse {
    token: string;
    user: { id: number; name: string; email: string }; // Define this based on your API response
}

const authService = {
    Login: async (email: string, password: string): Promise<AuthResponse> => {
        try {
            const response = await api.post('/auth/login', { email, password });
            if (response.data.token) {
                // Store the token using tokenUtils
                setAuthToken(response.data.token);
                return response.data;
              }
            return response.data;
        } catch (error: any) {
            throw new Error(error.response?.data?.message || 'Login failed');
        }
    },

    Logout: async (): Promise<void> => {
        try {
            await api.post('/logout');
        } catch (error: any) {
            throw new Error('Logout failed');
        }
    },
};

export default authService;
