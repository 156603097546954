import { Box } from "@mui/material";
import React from "react";

const Footer: React.FC = () => {
    return (
        <Box>
            <p>Footer</p>
        </Box>
    )
}
export default Footer;